
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import VendorService from "../../services/vendor-service";
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    PopUp
  },
})
export default class Vendor extends Vue {
  public filter = null;
  private title: string = "";
  public create_obj: any = {}

  public selected_items = [];
  public currentPage = 1;
  public fields = [
    {
      key: 'title',
      sortable: false
    },
    {
      key: 'email',
      sortable: false
    },
    {
      key: 'contact_num',
      sortable: false,

    },
    {
      key: 'type',
      sortable: false,
    }

  ];

  public items = [];
  public ddl_items: any = []
  public import_response = '';
  public import_file: any = null;

  get rows() {
    return this.selected_items.length;
  }
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  retrieve() {
    VendorService.get_list()
      .then((response) => {
        this.items = response.data;
        this.ddl_items = response.data;
        this.selected_items = this.items;
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieve();
  }

  submit_import() {
  }
  create() {
    this.error = ""
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const error_list = utils.getEmptyKeys(this.create_obj, ['title', 'email', 'contact_num', 'type'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else if (!emailRegex.test(this.create_obj.email)) {
      this.error = 'Invalid email address';
      return;
    }
    else {
      this.create_obj['user'] = 2;
      VendorService.create_list(this.create_obj).then((response) => {
        this.create_obj = {};
        // alert('Successfully Created')
        this.showModal("Created Successfully", [], "success")
        this.retrieve()
      })
        .catch((e) => {
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }

  // searchTitle() {
  //   VendorService.getByTitle(this.title)
  //     .then((response) => {
  //       this.data = response.data;
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }


  onRowClicked(item: any, index: any, event: any) {
    // this.$router.resolve({ path: `/create-list` });
  }

  mounted() {
    this.retrieve();
  }
  reset() {
    this.create_obj = {};
    this.error = "";
  }
}
