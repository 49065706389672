// import axios from "../common-axios";
import axios from "axios";

class VendorService {

  // project-item
  get_list() { return axios.get("/vendor/list/"); }

  get_list_by_id(id: any) { return axios.get(`/vendor/list/${id}/`); }

  create_list(data: any) { return axios.post("/vendor/list/", data); }

  getByTitle(title: any) {
    return axios.get(`/vendor/get-vendor/?title=${title}`);
  }
}

export default new VendorService();